<template>
    <section class="border-bottom pb-4 mb-4">
        <ShoppingCart
            :title="t('checkout_ReviewItemsConfirm')"
            title-class="text-primary text-uppercase h2 mb-0"
            class="readonly-border"
            :readonly="true"
        />
        <b-row class="mt-4">
            <b-col>
                <div class="d-flex justify-content-end">
                    <b-form-checkbox
                        :value="new Date()"
                        :unchecked-value="null"
                        @input="setAgreementDate($event)"
                        :state="setState($v.agreementDate)"
                    >
                        <span
                            v-html="
                                t('checkout_OrderAgreement', {
                                    url: shoppingCart.termsAndConditionsUrl,
                                })
                            "
                        >
                        </span>
                    </b-form-checkbox>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-3 text-center text-sm-left">
            <b-col sm="6" class="align-self-center">
                <template v-if="hasPricingPermission">
                    <span class="h4 mr-2"> {{ t('checkout_OrderTotal') }} </span>
                    <span class="h3 font-weight-bold">
                        {{ orderTotal | toCurrency }}
                    </span>
                </template>
            </b-col>
            <b-col sm="6">
                <b-button
                    @click="placeOrder"
                    :disabled="placeOrderButtonDisabled"
                    size="lg"
                    variant="primary"
                    class="btn-block-xs-only float-right"
                >
                    {{ t('checkout_PlaceOrder') }}
                </b-button>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import ShoppingCart from '@/views/shoppingcart/ShoppingCart.vue';

export default {
    name: 'ReviewConfirm',
    components: {
        ShoppingCart,
    },
    props: {
        value: {
            type: Date,
            default: () => new Date(),
        },
        placeOrderButtonDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            agreementDate: this.value,
        };
    },
    validations: {
        agreementDate: {
            required,
        },
    },
    computed: {
        ...mapState({
            shoppingCart: (state) => state.cart.shoppingCart,
        }),
        ...mapGetters({
            orderTotal: 'cart/orderTotal',
        }),
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
    },
    methods: {
        ...mapActions({
            fetchItemsCount: 'cart/fetchItemsCount',
            fetchItems: 'cart/fetchItems',
        }),
        ...mapActions('cart', ['resetRegulatoryItemCheck']),
        /**
         * Sets the validation state of a form element
         *
         * If the element hasn't been touched yet then do not display any
         * validation indication. Otherwise, display the validation result.
         *
         * @param {Boolean} disabledStatus the disabled status of the element
         * @param {Object} validationResult the validation result of the element
         */
        setState(validationResult) {
            return !validationResult.$dirty ? null : !validationResult.$invalid;
        },
        /**
         * Sets the agreement date
         *
         * If the input date is truthy, set the agreement date to the current
         * date. Otherwise, set it to the input date. This ensures the agreement
         * date corresponds to when the box is checked instead of when the page
         * is loaded.
         *
         * @param {Date} date
         */
        setAgreementDate(date) {
            this.agreementDate = date ? new Date() : date;
            this.$emit('input', this.agreementDate);
        },
        /**
         * Places the order if the form is valid
         *
         * After the order has been placed, the user is routed to the order
         * confirmation page and the item data is updated.
         */
        async placeOrder() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }
            this.$emit('update:wasOrderPlaced', true);

            const {success, results} = await this.blockingRequest(
                'orders/submit',
                this.shoppingCart
            );
            if (success) {
                // reset the regulatory check items in store after order is done.
                await this.resetRegulatoryItemCheck();
                this.$router.push({
                    name: 'OrderConfirmation',
                    params: {
                        defaultOrder: results,
                        isConfirmingOrder: true,
                    },
                });
                await this.fetchItemsCount();
                await this.fetchItems();
            }
        },
    },
    mounted() {
        this.$emit('update:wasOrderPlaced', false);
    },
};
</script>

<style lang="scss">
@import '../../../assets/css/variables';
.custom-checkbox {
    label.custom-control-label {
        line-height: 19px;

        span {
            color: $gray-darker;
        }

        &::before {
            border-radius: 0px;
        }
    }
}
</style>
